import React from "react";
import { Container, Table, Spinner, Button } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";
import TextField from "../../components/common/TextField";
import TextArea from "../../components/common/TextArea";
import AlertDismissible from "../../components/common/Alert";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { serverURL } from "../../config/motherhood";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class WWCCAdd extends React.Component {
  state = {
    loading: false,
    showSuccess: false,
    showApprove: false,
    state: "NSW",
  };

  xhttpFetchMinders = null;

  constructor(props) {
    super(props);
    this.xhttpFetchMinders = new XMLHttpRequest();
    this.xhttpFetchMinders.onreadystatechange = (xhttp) => {
      if (
        this.xhttpFetchMinders.readyState === 4 &&
        this.xhttpFetchMinders.status === 200
      ) {
        const response = JSON.parse(this.xhttpFetchMinders.response);
        console.log("response: ", response, typeof response, response.minders);
        this.setState({
          signup: false,
          error: true,
          loading: false,
          minders: response.minders || [],
          minderFetchLoading: false,
        });
      } else if (
        this.xhttpFetchMinders.readyState === 4 &&
        this.xhttpFetchMinders.status >= 300
      ) {
        //const response = JSON.parse(xhttp.response);
        alert(
          this.xhttpFetchMinders.responseText +
            " " +
            this.xhttpFetchMinders.status
        );
        this.setState({
          signup: false,
          error: true,
          loading: false,
          minderFetchLoading: false,
        });
      } else if (this.xhttpFetchMinders.readyState === 4) {
        this.setState({
          signup: false,
          loading: false,
          minderFetchLoading: false,
        });
      }
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onRatingClick = (value, name) => {
    this.setState({ [name]: value });
  };

  handleApprove = () => {
    console.log("this.state: ", this.state);

    //this.setState({ loading: true });
    const {
      minder,
      validFromDate,
      vaildToDate,
      verifiedOnDate,
      number,
      state,
    } = this.state;

    const certificate = {
      validFromDate: validFromDate.getTime() / 1000,
      vaildToDate: vaildToDate.getTime() / 1000,
      verifiedOnDate: verifiedOnDate.getTime() / 1000,
      number,
      state,
      checkedBy: firebase.auth().currentUser.uid,
      minderId: minder.userId,
      name: minder.firstName + " " + minder.lastName,
    };
    console.log("this.certificate: ", certificate);
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({
          signup: true,
          loading: false,
          showSuccess: true,
        });
      } else if (xhttp.readyState === 4 && xhttp.status >= 300) {
        //const response = JSON.parse(xhttp.response);
        alert(xhttp.responseText + " " + xhttp.status);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((userToken) => {
        xhttp.open("POST", serverURL + "/minderReferenceForm/admin/wwcc/add");
        xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
        xhttp.send(JSON.stringify(certificate));
      });
  };

  fetchMinders(name = "", uid) {
    if (name.length <= 2) {
      return;
    }
    console.log("name: ", name);
    //this.xhttpFetchMinders.abort();

    this.setState({ minderFetchLoading: true });
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((userToken) => {
        this.xhttpFetchMinders.open(
          "POST",
          serverURL + "/minderReferenceForm/admin/users/search"
        );
        this.xhttpFetchMinders.setRequestHeader(
          "Authorization",
          `Bearer ${userToken}`
        );
        this.xhttpFetchMinders.send(JSON.stringify({ name, uid }));
      });
  }

  disableApproveButton() {
    const {
      minder,
      validFromDate,
      vaildToDate,
      verifiedOnDate,
      number = "",
    } = this.state;

    let disabled =
      !minder ||
      number.length <= 3 ||
      !validFromDate ||
      !vaildToDate ||
      !verifiedOnDate;

    return disabled;
  }

  render() {
    const { intl } = this.props;
    const {
      minders = [],
      name = "",
      showApprove,
      showSuccess,
      loading,
      minderFetchLoading,
      selected,
      number = "",
      uid,
    } = this.state;
    console.log("minders: ", minders);
    let disableApprove = this.disableApproveButton();
    return (
      <React.Fragment>
        {showApprove && (
          <AlertDismissible
            title="Are you sure"
            message="Add this WWCC?"
            okTitle="Yes"
            cancelTitle="No"
            visable={showApprove}
            handleOk={() => {
              this.setState({ showApprove: false });
              this.handleApprove();
            }}
            handleCancel={() => this.setState({ showApprove: false })}
          />
        )}
        {showSuccess && (
          <AlertDismissible
            title="Success"
            message="WWCC Added."
            okTitle="OK"
            visable={showSuccess}
            handleOk={() => {
              this.setState({ showSuccess: false });
              window.location.reload(false);
            }}
          />
        )}
        {loading && <Loader />}
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage({ id: "form.wwcc.add.info" })}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <br />
                  <TextField
                    labelText={intl.formatMessage({
                      id: "form.wwcc.search.name",
                    })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "Search user name",
                    })}
                    name="name"
                    onChangeText={(value, name) => {
                      this.handleChange(name, value);
                      this.fetchMinders(name);
                    }}
                    defaultValue={name}
                  />
                  <hr className="mt-4" />
                  <div className="row">
                    <TextField
                      labelText={intl.formatMessage({
                        id: "form.wwcc.search.uid",
                      })}
                      type="text"
                      placeHolder={intl.formatMessage({
                        id: "Search user id",
                      })}
                      name="uid"
                      onChangeText={(value, name) => {
                        this.handleChange(name, value);
                      }}
                      defaultValue={uid}
                    />
                    <Button
                      style={{ height: 40 }}
                      onClick={() =>
                        this.fetchMinders(this.state.name, this.state.uid)
                      }
                    >
                      Search
                    </Button>
                  </div>
                  <hr className="mt-4" />
                  <label>Selected: {selected || ""}</label>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {minders.map((minder) => {
                        return (
                          <tr
                            onClick={() => {
                              if (selected === minder.userId) {
                                this.setState({
                                  selected: undefined,
                                  minder: undefined,
                                });
                              } else {
                                this.setState({
                                  selected: minder.userId,
                                  minder,
                                });
                              }
                            }}
                          >
                            <td>
                              {minder.displayName ||
                                minder.firstName + " " + minder.lastName}
                            </td>
                            <td>{minder.userId}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <hr className="mt-4" />
                  {minderFetchLoading && <Spinner animation="border" />}
                  <hr className="mt-4" />
                  <label>WWC Number</label>

                  <TextField
                    labelText={intl.formatMessage({ id: "form.wwcc.number" })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "WWC#######",
                    })}
                    name="number"
                    onChangeText={this.handleChange}
                    defaultValue={number}
                  />
                  <hr className="mt-4" />
                  <label>Vaild From:</label>

                  <DatePicker
                    selected={this.state.validFromDate}
                    onChange={(value) =>
                      this.handleChange("validFromDate", value)
                    }
                  />
                  <hr className="mt-4" />
                  <label>Vaild To:</label>

                  <DatePicker
                    selected={this.state.vaildToDate}
                    onChange={(value) =>
                      this.handleChange("vaildToDate", value)
                    }
                  />
                  <hr className="mt-4" />
                  <label>Select State</label>

                  <select
                    name="select"
                    id="stateSelect"
                    onChange={(obj) => {
                      console.log("vlaue: ", obj.target.value);
                      this.handleChange("state", obj.target.value);
                    }}
                  >
                    <option value="NSW">NSW</option>
                    <option value="QLD">QLD</option>
                    <option value="VIC">VIC</option>
                    <option value="SA">SA</option>
                    <option value="WA">WA</option>
                    <option value="NT">NT</option>
                  </select>
                  <hr className="mt-4" />
                  <label>Checked on:</label>

                  <DatePicker
                    selected={this.state.verifiedOnDate}
                    onChange={(value) =>
                      this.handleChange("verifiedOnDate", value)
                    }
                  />
                  <hr className="mt-4" />
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-4">
                      <button
                        disabled={disableApprove}
                        onClick={() => this.setState({ showApprove: true })}
                        type="button"
                        className="huddle-solid-button btn-block"
                      >
                        {intl.formatMessage({ id: "Add WWCC" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

WWCCAdd.propTypes = {
  intl: intlShape.isRequired,
};

const enhancer = injectIntl;

export default enhancer(WWCCAdd);
