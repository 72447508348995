import React from "react";
import { Container, CardColumns, Card } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { profileURL, serverURL } from "../../config/motherhood";

class PendingList extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.status === 200 && xhttp.readyState === 4) {
        // alert(xhttp.responseText);
        this.setState({
          isLoading: false,
          groupsList: JSON.parse(xhttp.response),
        });
      } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
        this.setState({ isLoading: false });
        alert("error: ", xhttp.responseText);
      }
    };
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        xhttp.open("GET", serverURL + "/minderReferenceForm/admin/groups/list");
        xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xhttp.send();
      });
  }

  render() {
    const { isLoading, groupsList = {} } = this.state;
    console.log("here:", groupsList);
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <Container>
          <button
            className="huddle-solid-button btn-block mb-4 mt-2"
            onClick={() => {
              let id = firebase.database().ref().push().key;
              let group = {
                id,
                details: {
                  id,
                  name: "set group name",
                  description: "group description",
                  enabled: true,
                  priority: 1,
                },
              };
              this.props.history.push({
                pathname: "/admin/groups/edit",
                state: { ...group, createGroup: true },
              });
            }}
          >
            add New Group
          </button>
          <CardColumns>
            {Object.values(groupsList).map((group) => {
              const { details = {}, id } = group;
              const { img, name, description } = details;
              return (
                <Card key={id}>
                  <Card.Header>
                    <div>
                      {img ? (
                        <img
                          variant="top"
                          src={img}
                          height="50px"
                          width="50px"
                          alt="group"
                        />
                      ) : null}
                      {name}
                    </div>
                    id: {id}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>Name: {name}</Card.Title>
                    <Card.Title>Description: {description}</Card.Title>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="huddle-solid-button btn-block"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/admin/groups/edit",
                          state: { ...group },
                        })
                      }
                    >
                      Edit
                    </button>
                  </Card.Footer>
                </Card>
              );
            })}
          </CardColumns>
        </Container>
      </React.Fragment>
    );
  }
}

export default PendingList;
