import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" className="bg-primary px-md-0">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              src={require("../../assets/images/huddle.png")}
              alt="huddle logo"
              className="mx-xs-2 px-2"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className="no-border"
            aria-controls="responsive-navbar-nav"
          >
            <div className="p-0">
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                color="white"
                className="mx-2"
              />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" className="text-left">
            <Nav className="ml-auto huddle-nav">
              <Nav.Link href="https://huddleapp.com.au/#vision">
                Our Vision
              </Nav.Link>
              <Nav.Link href="https://huddleapp.com.au/#how">
                How it Works
              </Nav.Link>
              <Nav.Link href="https://huddleapp.com.au/pricing">
                Pricing
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
export default Header;
