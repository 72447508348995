import React from "react";
import StarRatingComponent from "react-star-rating-controlled-component";

const RatingCard = ({ rating, size = "45px", onRatingChange }) => {
  if (rating === undefined) {
    return <div></div>;
  }

  const imageSelected = require("../../assets/images/star-selected.png");
  const imageUnSelected = require("../../assets/images/star.png");
  return (
    <StarRatingComponent
      name="rating"
      value={rating}
      starCount={5}
      renderStarIcon={(index, value) => (
        <span>
          <img
            width={size}
            height={size}
            src={index <= value ? imageSelected : imageUnSelected}
            alt=""
          />
        </span>
      )}
      editing={true}
      onStarClick={onRatingChange}
    />
  );
};

export default RatingCard;
