import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <React.Fragment>
      {" "}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 5,
          backgroundColor: "#00000055"
        }}
      ></div>
      <Spinner
        style={{
          position: "absolute",
          marginLeft: "50%",
          marginTop: "25%",
          zIndex: 10
        }}
        animation="grow"
        variant="primary"
      />
    </React.Fragment>
  );
};

export default Loader;
