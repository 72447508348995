import appLocaleData from "react-intl/locale-data/en";
import enMessages from "../locales/en_AU.json";

const en_AULang = {
  messages: {
    ...enMessages
  },
  locale: "en-AU",
  data: appLocaleData
};
export default en_AULang;
