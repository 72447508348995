import React from "react";
import { Container } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";
import TextField from "../../components/common/TextField";
import TextArea from "../../components/common/TextArea";
import Rating from "../../components/common/Rating";
import AlertDismissible from "../../components/common/Alert";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { serverURL } from "../../config/motherhood";

class Reference extends React.Component {
  state = { loading: false, showSuccess: false, showApprove: false };

  componentDidMount() {
    const {
      uid,
      token,
      name,
      organisation,
      relationship,
      experience,
      english,
      reliable,
      unsuitable,
      rating,
      review,
      contact,
      minder,
    } = this.props.location.state;
    this.setState({
      uid,
      token,
      name,
      organisation,
      relationship,
      experience,
      english,
      reliable,
      unsuitable,
      rating,
      review,
      contact,
      minder,
    });
  }
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onRatingClick = (value, name) => {
    this.setState({ [name]: value });
  };

  handleApprove = () => {
    this.setState({ loading: true });
    const {
      uid,
      token,
      name,
      organisation,
      relationship,
      experience,
      english,
      reliable,
      unsuitable,
      rating,
      review,
      contact,
      timestamp,
    } = this.state;

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({ signup: true, loading: false, showSuccess: true });
      } else if (xhttp.readyState === 4 && xhttp.status >= 400) {
        //const response = JSON.parse(xhttp.response);
        alert(xhttp.responseText);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((userToken) => {
        xhttp.open(
          "POST",
          serverURL + "/minderReferenceForm/admin/references/approve"
        );
        xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
        xhttp.send(
          JSON.stringify({
            token,
            uid,
            name,
            organisation,
            relationship,
            experience,
            english,
            reliable,
            unsuitable,
            rating,
            review,
            contact,
            timestamp,
          })
        );
      });
  };

  render() {
    const { intl } = this.props;
    const {
      loading,
      minder = {},
      name,
      organisation,
      relationship,
      experience,
      english,
      reliable,
      unsuitable,
      rating,
      review,
      contact,
      showApprove,
      showSuccess,
    } = this.state;

    return (
      <React.Fragment>
        {showApprove && (
          <AlertDismissible
            title="Are you sure"
            message="Approve this questionaire/review?"
            okTitle="Yes"
            cancelTitle="No"
            visable={showApprove}
            handleOk={() => {
              this.setState({ showApprove: false });
              this.handleApprove();
            }}
            handleCancel={() => this.setState({ showApprove: false })}
          />
        )}
        {showSuccess && (
          <AlertDismissible
            title="Approved"
            message="This has been approved."
            okTitle="OK"
            visable={showSuccess}
            handleOk={() => this.props.history.goBack()}
          />
        )}
        {loading && <Loader />}
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage(
                      { id: "form.info" },
                      { firstName: minder.firstName, lastName: minder.lastName }
                    )}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <br />
                  <label>{intl.formatMessage({ id: "form.title" })}</label>
                  <TextField
                    labelText={intl.formatMessage({ id: "question1" })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question1.placeholder",
                    })}
                    name="name"
                    onChangeText={this.handleChange}
                    defaultValue={name}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage({ id: "question2" })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question2.placeholder",
                    })}
                    name="organisation"
                    onChangeText={this.handleChange}
                    defaultValue={organisation}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question3" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question3.placeholder",
                    })}
                    name="relationship"
                    onChangeText={this.handleChange}
                    defaultValue={relationship}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      {
                        id: "question4",
                      },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question4.placeholder",
                    })}
                    name="experience"
                    onChangeText={this.handleChange}
                    defaultValue={experience}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question5" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question5.placeholder",
                    })}
                    name="english"
                    onChangeText={this.handleChange}
                    defaultValue={english}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question6" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question6.placeholder",
                    })}
                    name="reliable"
                    onChangeText={this.handleChange}
                    defaultValue={reliable}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question7" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question7.placeholder",
                    })}
                    name="unsuitable"
                    onChangeText={this.handleChange}
                    defaultValue={unsuitable}
                  />
                  <hr className="mt-4" />
                  <label>
                    {intl.formatMessage(
                      { id: "question8" },
                      { name: minder.firstName }
                    )}
                  </label>
                  <div className="row huddle-rating">
                    <Rating
                      rating={rating}
                      onRatingChange={this.onRatingClick}
                    />
                  </div>
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question9" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question9.placeholder",
                    })}
                    name="review"
                    onChangeText={this.handleChange}
                    defaultValue={review}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question10" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question10.placeholder",
                    })}
                    name="contact"
                    onChangeText={this.handleChange}
                    defaultValue={contact}
                  />
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-4">
                      <button
                        onClick={() => this.setState({ showApprove: true })}
                        type="button"
                        className="huddle-solid-button btn-block"
                      >
                        {intl.formatMessage({ id: "form.approve" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

Reference.propTypes = {
  intl: intlShape.isRequired,
};

const enhancer = injectIntl;

export default enhancer(Reference);
