import React from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";
import TextField from "../../components/common/TextField";
import TextArea from "../../components/common/TextArea";
import Rating from "../../components/common/Rating";
import AlertDismissible from "../../components/common/Alert";
import Loader from "../../components/common/Loader";
import { serverURL } from "../../config/motherhood";

class QuestionForm extends React.Component {
  state = { rating: 5, loading: false, showSuccess: false };

  componentDidMount() {
    const { token, id } = queryString.parse(this.props.location.search);

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        const response = JSON.parse(xhttp.responseText);

        this.setState({ ...response });
      } else if (xhttp.readyState === 4 && xhttp.status >= 400) {
        const response = JSON.parse(xhttp.responseText);
        alert(response.message);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };
    xhttp.open(
      "GET",
      `${serverURL}/minderReferenceForm/questionare?token=${token}&uid=${id}`
    );
    xhttp.send();
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onRatingClick = (value, name) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { token, id } = queryString.parse(this.props.location.search);
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({ signup: true, loading: false, showSuccess: true });
        alert("thanks");
      } else if (xhttp.readyState === 4 && xhttp.status >= 400) {
        const response = JSON.parse(xhttp.responseText);
        alert(response.message);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };
    xhttp.open("POST", `${serverURL}/minderReferenceForm/submit`);
    const {
      name,
      organisation,
      relationship,
      experience,
      english,
      reliable,
      unsuitable,
      rating,
      review,
      contact,
      minder
    } = this.state;
    xhttp.send(
      JSON.stringify({
        token,
        id,
        name,
        organisation,
        relationship,
        experience,
        english,
        reliable,
        unsuitable,
        rating,
        review,
        contact,
        minder
      })
    );
  };

  render() {
    const { intl } = this.props;
    const {
      loading,
      minder = {},
      name,
      organisation,
      showSuccess
    } = this.state;
   
    return (
      <React.Fragment>
        {showSuccess && (
          <AlertDismissible
            title="Thanks"
            message="Your questionaire has been submitted."
            okTitle="OK"
            visable={showSuccess}
            handleOk={() =>
              (document.location.href = "https://huddleapp.com.au")
            }
          />
        )}
        {loading && <Loader />}
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage(
                      { id: "form.info" },
                      { firstName: minder.firstName, lastName: minder.lastName }
                    )}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <br />
                  <label>{intl.formatMessage({ id: "form.title" })}</label>
                  <TextField
                    labelText={intl.formatMessage({ id: "question1" })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question1.placeholder"
                    })}
                    name="name"
                    onChangeText={this.handleChange}
                    defaultValue={name}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage({ id: "question2" })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question2.placeholder"
                    })}
                    name="organisation"
                    onChangeText={this.handleChange}
                    defaultValue={organisation}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question3" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question3.placeholder"
                    })}
                    name="relationship"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      {
                        id: "question4"
                      },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question4.placeholder"
                    })}
                    name="experience"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question5" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question5.placeholder"
                    })}
                    name="english"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question6" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question6.placeholder"
                    })}
                    name="reliable"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question7" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question7.placeholder"
                    })}
                    name="unsuitable"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <label>
                    {intl.formatMessage(
                      { id: "question8" },
                      { name: minder.firstName }
                    )}
                  </label>
                  <div className="row huddle-rating">
                    <Rating
                      rating={this.state.rating}
                      onRatingChange={this.onRatingClick}
                    />
                  </div>
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question9" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question9.placeholder"
                    })}
                    name="review"
                    onChangeText={this.handleChange}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage(
                      { id: "question10" },
                      { name: minder.firstName }
                    )}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "question10.placeholder"
                    })}
                    name="contact"
                    onChangeText={this.handleChange}
                  />
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-4">
                      <button
                        onClick={() => this.handleSubmit()}
                        type="button"
                        className="huddle-solid-button btn-block"
                      >
                        {intl.formatMessage({ id: "form.submit" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

QuestionForm.propTypes = {
  intl: intlShape.isRequired
};

const enhancer = injectIntl;

export default enhancer(QuestionForm);
