import React from "react";
import { Container, CardColumns, Card, Dropdown } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { serverURL } from "../../config/motherhood";

class MindersList extends React.Component {
  state = { isLoading: true, updatedBio: {} };

  componentDidMount() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.status === 200 && xhttp.readyState === 4) {
        // alert(xhttp.responseText);
        let referenceList = JSON.parse(xhttp.response) || [];
        referenceList.sort((a, b) => b.timestamp - a.timestamp);

        this.setState({
          isLoading: false,
          referenceList,
        });
      } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
        this.setState({ isLoading: false });
        alert("error: ", xhttp.responseText);
      }
    };

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        xhttp.open(
          "GET",
          serverURL + "/minderReferenceForm/admin/mindersListReview/list"
        );
        xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xhttp.send();
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  approveMindersList = async (userId, index, updatedBio) => {
    try {
      const xhttp = new XMLHttpRequest();

      this.setState({ isLoading: true });
      xhttp.onreadystatechange = () => {
        if (xhttp.status === 200 && xhttp.readyState === 4) {
          alert("approved!");
          const { referenceList } = this.state;
          referenceList.splice(index, 1);
          this.setState({ referenceList, isLoading: false });
        } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
          alert("error: ", xhttp.responseText);

          this.setState({ isLoading: false });
        }
      };

      const userToken = await firebase.auth().currentUser.getIdToken(true);

      xhttp.open(
        "POST",
        serverURL + "/minderReferenceForm/admin/mindersListReview/approve"
      );
      xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
      xhttp.send(JSON.stringify({ userId, updatedBio }));
    } catch (err) {
      alert(err.message);
    }
  };

  render() {
    const { isLoading, referenceList = [], updatedBio } = this.state;
    console.log("updatedBio", updatedBio);

    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <Container>
          <CardColumns>
            {referenceList.length == 0 && !isLoading && (
              <Card>
                <Card.Header>Nothing to review</Card.Header>
              </Card>
            )}
            {referenceList.map((minder, index) => {
              const {
                userId,
                firstName = "",
                lastName = "",
                photos = {},
                profileURL,
                about = "",
              } = minder;

              let bio = updatedBio[userId];
              return (
                <Card key={userId}>
                  <Card.Header>
                    <div>
                      {profileURL && (
                        <a href={profileURL}>
                          <img
                            variant="top"
                            src={profileURL}
                            height="50px"
                            width="50px"
                            alt="minder"
                          />
                        </a>
                      )}
                      <Card.Title>
                        Name: {firstName + " " + lastName}
                      </Card.Title>
                    </div>
                    <Card.Title>UserId: {userId}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {Object.values(photos).map((photo) => {
                      console.log("photo: ", photos);
                      return (
                        <a href={photo.uri}>
                          <img
                            variant="top"
                            src={photo.uri}
                            height="150px"
                            width="150px"
                            alt="minder"
                          />
                        </a>
                      );
                    })}
                    <Dropdown.Divider></Dropdown.Divider>
                    <Card.Title>Minder Bio</Card.Title>
                    <textarea
                      style={{ width: "100%", minHeight: 100 }}
                      type="textarea"
                      value={bio || about}
                      onChange={(e) => {
                        updatedBio[userId] = e.target.value;
                        this.setState({ updatedBio });
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="huddle-solid-button btn-block"
                      onClick={() =>
                        this.approveMindersList(userId, index, bio)
                      }
                    >
                      Approve
                    </button>
                  </Card.Footer>
                </Card>
              );
            })}
          </CardColumns>
        </Container>
      </React.Fragment>
    );
  }
}

export default MindersList;
