import React from "react";
import { Container } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";
import TextField from "../../components/common/TextField";
import TextArea from "../../components/common/TextArea";
import Rating from "../../components/common/Rating";
import AlertDismissible from "../../components/common/Alert";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { serverURL, profileURL } from "../../config/motherhood";
console.log("url: ", serverURL);

function FieldOptions({ value, onChange }) {
  return (
    <select
      onChange={(evt) => {
        console.log(evt.target.value);
        onChange(evt.target.value);
      }}
    >
      <option value="email" selected={value == "email"}>
        email
      </option>
      <option value="firstName" selected={value == "firstName"}>
        first name
      </option>
      <option value="attributes" selected={value == "attributes"}>
        attributes
      </option>
    </select>
  );
}

function MatchOptions({ value, onChange }) {
  return (
    <select
      onChange={(evt) => {
        console.log(evt.target.value);
        onChange(evt.target.value);
      }}
    >
      <option value="equalTo" selected={value == "equalTo"}>
        equal
      </option>
      <option value="includes" selected={value == "includes"}>
        includes
      </option>
      <option value="notEqualTo" selected={value == "notEqualTo"}>
        not equal
      </option>
    </select>
  );
}

function JoinOptions({ value, onChange }) {
  return (
    <select
      onChange={(evt) => {
        console.log(evt.target.value);
        onChange(evt.target.value);
      }}
    >
      <option value="and" selected={value == "and"}>
        And
      </option>
      <option value="or" selected={value == "or"}>
        Or
      </option>
    </select>
  );
}

class GroupEdit extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { state: group } = location;
    const { rules = {} } = group;

    this.state = {
      loading: false,
      showSuccess: false,
      showApprove: false,
      localRules: { ...rules },
      group,
    };
  }

  handleChange = (name, value) => {
    console.log("name: ", name, value);
    const { group } = this.state;
    group[name] = value;
    this.setState({ group });
  };

  onRatingClick = (value, name) => {
    this.setState({ [name]: value });
  };

  handleApprove = async () => {
    this.setState({ loading: true });
    const { group, localRules, _img } = this.state;
    const { details, createGroup = false, id } = group;
    const { name, enabled, description } = details;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({ loading: false });
      } else if (xhttp.readyState === 4 && xhttp.status >= 300) {
        //const response = JSON.parse(xhttp.response);
        alert(xhttp.responseText + " " + xhttp.status);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };

    let userToken = await firebase.auth().currentUser.getIdToken(true);

    xhttp.open(
      "POST",
      createGroup
        ? serverURL + "/minderReferenceForm/admin/groups/add"
        : serverURL + "/minderReferenceForm/admin/groups/edit"
    );
    xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
    let data = {
      id,
      details: { name, enabled, description, id },
      rules: localRules,
    };
    if (createGroup) {
      console.log("groups");
      data.details.id = id;
    }
    xhttp.send(JSON.stringify(data));
    if (_img) {
      xhttp.open(
        "POST",
        serverURL + "/minderReferenceForm/admin/groups/upload"
      );
      xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
      xhttp.send(JSON.stringify({ id, file: _img }));
    }
    console.log("data", data);
  };

  render() {
    const { intl, location } = this.props;
    const {
      loading,
      showApprove,
      showSuccess,
      localRules = {},
      group,
      _img,
    } = this.state;
    //const { state: group } = location;
    console.log("groups: ", group);
    let { details, id, recomened, rules = {}, members = {} } = group;
    let { name, description, img, enabled = true } = details;

    return (
      <React.Fragment>
        {showApprove && (
          <AlertDismissible
            title="Are you sure"
            message="Edit this group"
            okTitle="Yes"
            cancelTitle="No"
            visable={showApprove}
            handleOk={() => {
              this.setState({ showApprove: false });
              this.handleApprove();
            }}
            handleCancel={() => this.setState({ showApprove: false })}
          />
        )}
        {showSuccess && (
          <AlertDismissible
            title="Approved"
            message="This has been approved."
            okTitle="OK"
            visable={showSuccess}
            handleOk={() => {
              this.setState({ showSuccess: false });
              window.location.reload(false);
            }}
          />
        )}
        {loading && <Loader />}
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage({ id: "form.groups.edit.info" })}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <div style={{ width: 140, height: 140 }}>
                    Image:
                    {img || _img ? (
                      <img
                        src={_img || img}
                        style={{ width: 80, height: 80, margin: 10 }}
                      />
                    ) : null}
                    <input
                      type="file"
                      onChange={({ target }) => {
                        console.log("file", target.files[0]);
                        const reader = new FileReader();
                        const file = target.files[0];

                        reader.addEventListener(
                          "load",
                          function () {
                            // convert image file to base64 string
                            let _img = reader.result;
                            this.setState({ _img });
                          }.bind(this),
                          false
                        );

                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </div>
                  <br />
                  <TextField
                    labelText={intl.formatMessage({ id: "group.Id" })}
                    type="text"
                    name="id"
                    onChangeText={this.handleChange}
                    defaultValue={id}
                  />
                  <div>
                    <label>Enabled </label>
                    <input
                      type="checkbox"
                      checked={Boolean(enabled)}
                      onClick={() => {
                        group.details.enabled = !enabled;
                        this.setState({ group });
                      }}
                    />
                  </div>
                  <hr className="mt-4" />
                  <div>
                    <label>
                      Priority - custom groups should be 1 or greater{" "}
                    </label>
                    <input
                      defaultValue={group.details.priority || 0}
                      type="number"
                      onClick={({ target }) => {
                        group.details.priority = Number(target.value || 0);
                        this.setState({ group });
                      }}
                    />
                  </div>
                  <hr className="mt-4" />
                  <TextField
                    labelText={intl.formatMessage({ id: "name" })}
                    type="text"
                    name="details.name"
                    onChangeText={(_, value) => {
                      group.details.name = value;
                      this.setState({ group });
                    }}
                    defaultValue={name}
                  />
                  <hr className="mt-4" />
                  <TextArea
                    labelText={intl.formatMessage({ id: "description" })}
                    type="text"
                    name="description"
                    onChangeText={(_, value) => {
                      group.details.description = value;
                      this.setState({ group });
                    }}
                    defaultValue={description}
                  />
                  <hr className="mt-4" />

                  {Object.values(localRules).map((rule) => {
                    const { value, field, match, id, join } = rule;
                    return (
                      <div className="row">
                        <FieldOptions
                          value={field}
                          onChange={(value) => {
                            localRules[id].field = value;
                            this.setState({
                              localRules: { ...localRules },
                            });
                          }}
                        />
                        <MatchOptions
                          value={match}
                          onChange={(value) => {
                            localRules[id].match = value;
                            this.setState({
                              localRules: { ...localRules },
                            });
                          }}
                        />
                        <TextField
                          labelText={intl.formatMessage({ id: "value" })}
                          type="text"
                          name="rule.value"
                          onChangeText={(_, value) => {
                            localRules[id].value = value;
                            this.setState({
                              localRules: { ...localRules },
                            });
                          }}
                          defaultValue={value}
                        />
                        <JoinOptions
                          value={join}
                          type="text"
                          name="rule.join"
                          onChange={(value) => {
                            localRules[id].join = value;
                            this.setState({
                              localRules: { ...localRules },
                            });
                          }}
                        />
                        <button
                          onClick={() => {
                            if (Object.keys(localRules).length <= 1) {
                              return;
                            }
                            console.log(" localRules[id];", localRules, id);
                            delete localRules[id];
                            this.setState({
                              localRules: { ...localRules },
                            });
                          }}
                        >
                          delete
                        </button>
                      </div>
                    );
                  })}

                  <button
                    onClick={() => {
                      let id = firebase.database().ref("motherhood").push().key;
                      this.setState({
                        localRules: {
                          ...localRules,
                          [id]: {
                            id,
                            match: "equalTo",
                            field: "email",
                            value: "",
                            join: "and",
                          },
                        },
                      });
                    }}
                  >
                    add rule
                  </button>
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-4">
                      <button
                        onClick={() => this.setState({ showApprove: true })}
                        type="button"
                        className="huddle-solid-button btn-block"
                      >
                        {intl.formatMessage({ id: "form.update" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-4">
                <h1>Members: {Object.keys(members).length}</h1>
                <div
                  style={{ width: "100%", height: "300px", overflow: "scroll" }}
                >
                  <table>
                    <tr>
                      <th>pic</th>
                      <th>id</th>
                    </tr>
                    {Object.keys(members).map((member) => (
                      <tr>
                        <td style={{ minWidth: 60, paddingLeft: 5 }}>
                          <img
                            width="40px"
                            height="40px"
                            src={`${profileURL}${member}&size=small&h=40&w=40`}
                          />
                        </td>
                        <td>{member}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

GroupEdit.propTypes = {
  intl: intlShape.isRequired,
};

const enhancer = injectIntl;

export default enhancer(GroupEdit);
