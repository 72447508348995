import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";

const AlertDismissible = ({
  visable = true,
  title,
  message,
  okTitle,
  handleOk,
  cancelTitle,
  handleCancel
}) => {
  const [show, setShow] = useState(visable);

  return (
    <>
      <Modal show={show}>
        <Alert show={show} variant="success">
          <Alert.Heading>{title}</Alert.Heading>
          <p>{message}</p>
          <hr />
          <div className="d-flex justify-content-end">
            {cancelTitle && (
              <Button
                onClick={() => {
                  if (handleCancel) handleCancel();
                  setShow(false);
                }}
                variant="outline-success mr-4"
              >
                {cancelTitle}
              </Button>
            )}
            <Button
              onClick={() => {
                setShow(false);
                if (handleOk) handleOk();
              }}
              variant="outline-success"
            >
              {okTitle}
            </Button>
          </div>
        </Alert>
      </Modal>
    </>
  );
};

export default AlertDismissible;
