import React from "react";
import { Container, CardColumns, Card } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { profileURL, serverURL } from "../../config/motherhood";

class PendingList extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.status === 200 && xhttp.readyState === 4) {
        // alert(xhttp.responseText);
        this.setState({
          isLoading: false,
          referenceList: JSON.parse(xhttp.response),
        });
      } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
        this.setState({ isLoading: false });
        alert("error: ", xhttp.responseText);
      }
    };
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        xhttp.open(
          "GET",
          serverURL + "/minderReferenceForm/admin/references/submitted"
        );
        xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xhttp.send();
      });
  }

  render() {
    const { isLoading, referenceList = {} } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <Container>
          <CardColumns>
            {Object.keys(referenceList).map((key) => {
              let ref = referenceList[key];

              return Object.keys(ref).map((id) => {
                let reference = ref[id];

                const {
                  name,
                  organisation,
                  relationship,
                  experience,
                  english,
                  reliable,
                  unsuitable,
                  rating,
                  review,
                  contact,
                  minder = {},
                  timestamp,
                } = reference;
                return (
                  <Card key={id}>
                    <Card.Header>
                      <div>
                        <img
                          variant="top"
                          src={profileURL + key}
                          height="50px"
                          width="50px"
                          alt="minder"
                        />
                        {minder.firstName} {minder.lastName}
                      </div>
                      <div>uid: {key}</div>
                      id: {id}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>Name: {reference.name}</Card.Title>
                      <Card.Title>
                        Organisation: {reference.organisation}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer>
                      <button
                        className="huddle-solid-button btn-block"
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/admin/reference/submitted",
                            state: {
                              token: id,
                              uid: key,
                              name,
                              organisation,
                              relationship,
                              experience,
                              english,
                              reliable,
                              unsuitable,
                              rating,
                              review,
                              contact,
                              minder,
                              timestamp,
                            },
                          })
                        }
                      >
                        View
                      </button>
                    </Card.Footer>
                  </Card>
                );
              });
            })}
          </CardColumns>
        </Container>
      </React.Fragment>
    );
  }
}

export default PendingList;
