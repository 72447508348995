import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import firebase from "./services/firebase";
import "./App.css";
import Header from "./components/Header/index";
import QuestionForm from "./screens/QuestionForm";
import ReferenceList from "./screens/ReferenceList";
import PendingList from "./screens/PendingList";
import Reference from "./screens/Reference";
import ReferenceAdd from "./screens/ReferenceAdd";
import PayMinder from "./screens/PayMinder";
import SubscriptionUpdate from "./screens/SubscriptionUpdate";
import WWCCAdd from "./screens/WWCCAdd";
import Login from "./screens/Login";
import UsersDelete from "./screens/UsersDelete";
import JobNotifications from "./screens/JobNotifications";
import List from "./screens/List";
import MindersListReview from "./screens/MinderListReview";
import JobsDelete from "./screens/JobsDelete";
import GroupsList from "./screens/GroupsList";
import GroupsEdit from "./screens/GroupsEdit";
import UsersExclude from "./screens/UsersExclude";

const ProtectedRoute = (component) => {
  if (firebase.auth().currentUser) {
    return component;
  }
  return <Login />;
};

class App extends React.Component {
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ currentUser: user, loadingAuth: false });
    });
  }

  render() {
    return (
      <div className="App">
        <Header />
        <BrowserRouter>
          <Route
            path="/questionnaire"
            render={(props) => <QuestionForm {...props} />}
          />
          <Route
            path="/admin/reference/list"
            component={(props) => ProtectedRoute(<ReferenceList {...props} />)}
          />
          <Route
            path="/admin/reference/pending"
            component={(props) => ProtectedRoute(<PendingList {...props} />)}
          />
          <Route
            path="/admin/reference/submitted"
            component={(props) => ProtectedRoute(<Reference {...props} />)}
          />
          <Route
            path="/admin/reference/add"
            component={(props) => ProtectedRoute(<ReferenceAdd {...props} />)}
          />
          <Route
            path="/admin/payments/create"
            component={(props) => ProtectedRoute(<PayMinder {...props} />)}
          />
          <Route
            path="/admin/subscription/update"
            component={(props) =>
              ProtectedRoute(<SubscriptionUpdate {...props} />)
            }
          />
          <Route
            path="/admin/wwcc/add"
            component={(props) => ProtectedRoute(<WWCCAdd {...props} />)}
          />
          <Route
            path="/admin/users/delete"
            component={(props) => ProtectedRoute(<UsersDelete {...props} />)}
          />
          <Route
            path="/admin/jobs/delete"
            component={(props) => ProtectedRoute(<JobsDelete {...props} />)}
          />
          <Route
            path="/admin/notification/job/invite"
            component={(props) =>
              ProtectedRoute(<JobNotifications {...props} />)
            }
          />
          <Route
            path="/admin/minders/list/review"
            component={(props) =>
              ProtectedRoute(<MindersListReview {...props} />)
            }
          />
          <Route
            path="/admin/links"
            component={(props) => ProtectedRoute(<List {...props} />)}
          />
          <Route
            path="/admin/groups/list"
            component={(props) => ProtectedRoute(<GroupsList {...props} />)}
          />
          <Route
            path="/admin/groups/edit"
            component={(props) => ProtectedRoute(<GroupsEdit {...props} />)}
          />
          <Route
            path="/admin/minders/list/exclude"
            component={(props) => ProtectedRoute(<UsersExclude {...props} />)}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
