import React from "react";
import { Container } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";
import TextField from "../../components/common/TextField";
import AlertDismissible from "../../components/common/Alert";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { serverURL } from "../../config/motherhood";

class UsersDelete extends React.Component {
  state = { loading: false, showSuccess: false, showApprove: false };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleApprove = () => {
    this.setState({ loading: true });
    const { userId } = this.state;

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        this.setState({
          signup: true,
          loading: false,
          showSuccess: true,
          userId: "",
          subscriptionDate: "",
        });
      } else if (xhttp.readyState === 4 && xhttp.status >= 300) {
        //const response = JSON.parse(xhttp.response);
        alert(xhttp.responseText + " " + xhttp.status);
        this.setState({ signup: false, error: true, loading: false });
      } else if (xhttp.readyState === 4) {
        this.setState({ signup: false, loading: false });
      }
    };

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((userToken) => {
        xhttp.open(
          "DELETE",
          serverURL + "/minderReferenceForm/admin/users/delete"
        );
        xhttp.setRequestHeader("Authorization", `Bearer ${userToken}`);
        xhttp.send(
          JSON.stringify({
            userId,
          })
        );
      });
  };

  render() {
    const { intl } = this.props;
    const { userId, loading, showApprove, showSuccess } = this.state;

    return (
      <React.Fragment>
        {showApprove && (
          <AlertDismissible
            title="Are you sure"
            message={`THIS WILL DELETE ALL USER DATA FOR ${userId}`}
            okTitle="Yes"
            cancelTitle="No"
            visable={showApprove}
            handleOk={() => {
              this.setState({ showApprove: false });
              this.handleApprove();
            }}
            handleCancel={() => this.setState({ showApprove: false })}
          />
        )}
        {showSuccess && (
          <AlertDismissible
            title="Success"
            message="User has been deleted"
            okTitle="OK"
            visable={showSuccess}
            handleOk={() => {
              this.setState({ showSuccess: false });
              window.location.reload(false);
            }}
          />
        )}
        {loading && <Loader />}
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage({ id: "users.delete.title" })}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <br />
                  <TextField
                    labelText={intl.formatMessage({
                      id: "users.delete.userId",
                    })}
                    type="text"
                    placeHolder={intl.formatMessage({
                      id: "users.delete.placeholder.userId",
                    })}
                    name="userId"
                    onChangeText={this.handleChange}
                    defaultValue={userId}
                  />
                  <hr className="mt-4" />

                  <hr className="mt-4" />
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-4">
                      <button
                        onClick={() => this.setState({ showApprove: true })}
                        type="button"
                        className="huddle-solid-button btn-block"
                      >
                        {intl.formatMessage({
                          id: "users.delete.delete.button",
                        })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

UsersDelete.propTypes = {
  intl: intlShape.isRequired,
};

const enhancer = injectIntl;

export default enhancer(UsersDelete);
