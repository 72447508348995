// DEV ////
/*
exports.profileURL =
  "https://huddleapp-dev.firebaseapp.com/api/minders/profilepic/?id=";
exports.serverURL = "https://us-central1-huddleweb-admindev.cloudfunctions.net"; //"http://localhost:5001/huddleweb-admindev/us-central1";
exports.sendEmailURL =
  "https://us-central1-huddleapp-dev.cloudfunctions.net/sendMinderRefEmail";

exports.firebaseConfig = {
  apiKey: "AIzaSyAYlhbREUuKW5uZ2ELDu_6b-oYJvUJC3oU",
  authDomain: "huddleweb-admindev.firebaseapp.com",
  databaseURL: "https://huddleweb-admindev.firebaseio.com",
  projectId: "huddleweb-admindev",
  storageBucket: "huddleweb-admindev.appspot.com",
  messagingSenderId: "190236779896",
  appId: "1:190236779896:web:872e4fb3accce839d6d48d",
};
*/
////////////////

// PROD ////

exports.profileURL =
  "https://huddleapp-prod.firebaseapp.com/api/minders/profilepic/?id=";
exports.serverURL =
  "https://us-central1-huddleweb-minderreference.cloudfunctions.net";
exports.sendEmailURL =
  "https://us-central1-huddleapp-prod.cloudfunctions.net/sendMinderRefEmail";

exports.firebaseConfig = {
  apiKey: "AIzaSyDtWGQaKg4vIfrboOqn-jfnZVishpkcZSk",
  authDomain: "huddleweb-minderreference.firebaseapp.com",
  databaseURL: "https://huddleweb-minderreference.firebaseio.com",
  projectId: "huddleweb-minderreference",
  storageBucket: "huddleweb-minderreference.appspot.com",
  messagingSenderId: "948331005686",
  appId: "1:948331005686:web:d49d60d18a2bcfed84ced7",
  measurementId: "G-3J5RFVS8M7",
};

////////////////
