import React from "react";
import { Container, CardColumns, Card } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import firebase from "../../services/firebase";
import { profileURL, serverURL, sendEmailURL } from "../../config/motherhood";

class ReferenceList extends React.Component {
  state = { isLoading: true, sendingLink: {} };

  componentDidMount() {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.status === 200 && xhttp.readyState === 4) {
        // alert(xhttp.responseText);
        this.setState({
          isLoading: false,
          referenceList: JSON.parse(xhttp.response),
        });
      } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
        this.setState({ isLoading: false });
        alert("error: ", xhttp.responseText);
      }
    };

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        xhttp.open(
          "GET",
          serverURL + "/minderReferenceForm/admin/references/list"
        );
        xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xhttp.send();
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  sendEmailLink = (uid, token, name, email, minder) => {
    const xhttp = new XMLHttpRequest();
    const { sendingLink } = this.state;
    sendingLink[token] = "sending";
    this.setState({ sendingLink });
    xhttp.onreadystatechange = () => {
      if (xhttp.status === 200 && xhttp.readyState === 4) {
        alert("sent!");
        delete sendingLink[token];
        this.setState({ sendingLink });
      } else if ((xhttp.status >= 400) & (xhttp.readyState === 4)) {
        alert("error: ", xhttp.responseText);
        delete sendingLink[token];
        this.setState({ sendingLink });
      } else if (xhttp.readyState === 4) {
        delete sendingLink[token];
        this.setState({ sendingLink });
      }
    };
    xhttp.open("POST", sendEmailURL);
    xhttp.send(JSON.stringify({ uid, token, name, email, minder }));
  };

  render() {
    const { isLoading, referenceList = {}, sendingLink = {} } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <Container>
          <CardColumns>
            {Object.keys(referenceList).map((key) => {
              let ref = referenceList[key];

              return Object.keys(ref).map((id) => {
                let reference = ref[id];
                return (
                  <Card key={id}>
                    <Card.Header>
                      <img
                        variant="top"
                        src={profileURL + key}
                        height="50px"
                        width="50px"
                        alt="minder"
                      />
                      {key}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>Name: {reference.name}</Card.Title>
                      <Card.Title>Email: {reference.email}</Card.Title>
                      <Card.Title>Number: {reference.number}</Card.Title>
                      <Card.Title>
                        Organisation: {reference.organisation}
                      </Card.Title>
                      <Card.Text>
                        Last Email Link Sent :{" "}
                        {reference.emailLinkTimestamp
                          ? new Date(
                              reference.emailLinkTimestamp * 1000
                            ).toDateString()
                          : "Never"}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <button
                        disabled={sendingLink[id] ? true : false}
                        className="huddle-solid-button btn-block"
                        onClick={() =>
                          this.sendEmailLink(
                            key,
                            id,
                            reference.name,
                            reference.email,
                            reference.minder
                          )
                        }
                      >
                        {sendingLink[id] ? "Sending" : "Send Email Link"}
                      </button>
                    </Card.Footer>
                  </Card>
                );
              });
            })}
          </CardColumns>
        </Container>
      </React.Fragment>
    );
  }
}

export default ReferenceList;
