import React from "react";
import { Container } from "react-bootstrap";
import { injectIntl, intlShape } from "react-intl";

class List extends React.Component {
  state = { loading: false, showSuccess: false, showApprove: false };

  render() {
    const { intl } = this.props;

    return (
      <React.Fragment>
        <Container text="true">
          <div className="row justify-content-md-center">
            <div className="col-md-9 mt-4 mb-4">
              <div className="card">
                <div className="header-primary huddle-title">
                  <h4 className="text-md-left huddle-subtext">
                    {intl.formatMessage({ id: "Admin Links" })}
                  </h4>
                </div>
                <div className="card-body huddle-container">
                  <br />
                  <br />
                  <a href="/admin/reference/list">/admin/reference/list</a>
                  <br />
                  <br />
                  <a href="/admin/reference/pending">
                    /admin/reference/pending
                  </a>
                  <br />
                  <br />
                  <a href="/admin/reference/add">/admin/reference/add</a>
                  <br />
                  <br />
                  <a href="/admin/payments/create">/admin/payments/create</a>
                  <br />
                  <br />
                  <a href="/admin/subscription/update">
                    /admin/subscription/update
                  </a>
                  <br />
                  <br />
                  <a href="/admin/wwcc/add">/admin/wwcc/add</a>
                  <br />
                  <br />
                  <a href="/admin/users/delete">/admin/users/delete</a>
                  <br />
                  <br />
                  <a href="/admin/jobs/delete">/admin/jobs/delete</a>
                  <br />
                  <br />
                  <a href="/admin/notification/job/invite">
                    /admin/notification/job/invite
                  </a>
                  <br />
                  <br />
                  <a href="/admin/minders/list/review">
                    /admin/minders/list/review
                  </a>
                  <br />
                  <br />
                  <a href=" /admin/minders/list/exclude">
                    /admin/minders/list/exclude
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

List.propTypes = {
  intl: intlShape.isRequired,
};

const enhancer = injectIntl;

export default enhancer(List);
