import React from "react";
import ReactDOM from "react-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import "./assets/css/main.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import AppLocale from "./lngProvider";

const currentAppLocale = AppLocale["en_AU"];

ReactDOM.render(
  <IntlProvider
    locale={currentAppLocale.locale}
    messages={currentAppLocale.messages}
  >
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
