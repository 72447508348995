import { addLocaleData } from "react-intl";
import en_AULang from "./entries/en-AU";

const AppLocale = {
  en_AU: en_AULang
};

addLocaleData(AppLocale.en_AU.data);

export default AppLocale;
