import React from "react";
import { Container } from "react-bootstrap";
import TextField from "../../components/common/TextField";
import Loader from "../../components/common/Loader";
import { injectIntl } from "react-intl";
import firebase from "firebase"; //"../../services/firebase";

class Login extends React.Component {
  state = { email: "", password: "", isLoading: false };

  onTextChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    console.log("auth");
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.setState({ isLoading: false });
        if (user) {
        } else {
          alert("Login Failed");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        alert(err.message);
      });
  };

  render() {
    const { intl } = this.props;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Loader />}

        <Container>
          <div>
            <div>
              <TextField
                labelText="Email"
                placeHolder="Your Email address"
                name="email"
                type="email"
                onChangeText={this.onTextChange}
              />
              <TextField
                labelText="Password"
                placeHolder="Your password"
                name="password"
                type="password"
                onChangeText={this.onTextChange}
              />
            </div>
            <button
              onClick={() => this.handleSubmit()}
              type="button"
              className="huddle-solid-button btn-block"
            >
              {intl.formatMessage({ id: "form.submit" })}
            </button>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default injectIntl(Login);
